import React from "react";
import SEO from "../../components/common/seo";
import LayoutThird from "../../components/common/layout-third";
import IdentityNav from "../../components/common/identity-nav";
import Partners from "../../components/common/partners";
import Interested from "../../components/identity/interested";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import ContactBox from "../../components/common/contact-box";
import {graphql} from "gatsby";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const CasosDeUsoPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiIdentityCasosDeUso.edges[0].node;

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-ide.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <IdentityNav pageContext={pageContext}/>

            <section className="pt-10 pb-10 bg-grey">
                <div className="container">
                    <div className="row">
                        {page.items.map((item, i) => {
                            return <div className="col-xl-6 mb-3" key={i}>
                                <div className="card card-border shadow-light-lg lift lift-lg mb-5" style={{borderTopColor: '#f4364c'}} data-aos="fade-left"
                                     data-aos-delay={i * 100}>
                                    <div className="card-body text-center">
                                        <br/>
                                        <span className="badge badge-pill badge-success-soft mb-5">
                                            <span className="h5 font-weight-bold spacing">{item.title}</span>
                                        </span>

                                        <br/>

                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('mb-5 spacing font-size-md text-left')}/>
                                    </div>
                                </div>
                            </div>
                        })}

                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Identity"/>

            <ModalContactSales pageContext={pageContext} product="Identity"/>

            <ModalWhitePaper pageContext={pageContext}/>
        </LayoutThird>
    )
}

export default CasosDeUsoPage

export const casosDeUsoPageQuery = graphql
    `query($lang: String) {
        allStrapiIdentityCasosDeUso ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    id
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    items {
                        order
                        title
                        description
                    }
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
}`;
